import React from 'react';
import { Button, Space, Table, Tag } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const UsersTable = ({ users, onSelect, onRemove, stations }) => {
	return (
		<Table
			columns={[
				{
					title: 'Email',
					dataIndex: 'email',
					key: 'email'
				},
				{
					title: 'Station',
					dataIndex: 'stationId',
					//key: 'stationId',
					render: (stationId) => {
						const station = stations.find((r) => r._id === stationId);
						const name = station ? station.name : stationId;
						return name;
					}
				},
				{
					title: 'UserId',
					dataIndex: 'username',
					key: 'username'
				},
				{
					title: 'isAdmin',
					key: 'isAdmin',
					dataIndex: 'isAdmin',
					render: (isAdmin) => <Tag color={isAdmin ? 'green' : 'red'}>{isAdmin ? 'Ja' : 'Nee'}</Tag>
				},
				{
					title: 'Action',
					key: 'action',
					className: 'column-align-right',
					render: (text, record) => {
						return (
							<Space>
								<Button
									style={{ float: 'right' }}
									type="primary"
									shape="circle"
									icon={<EditOutlined />}
									onClick={() => onSelect(record)}
								/>
								<Button
									style={{ float: 'right' }}
									type="primary"
									shape="circle"
									icon={<DeleteOutlined />}
									onClick={() => onRemove(record)}
									danger
								/>
							</Space>
						);
					}
				}
			]}
			rowKey="username"
			dataSource={users}
		/>
	);
};
export default UsersTable;
