import React, { useState } from 'react';
import { Form, Button, Select, Checkbox, Input } from 'antd';

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 5 }
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 12 }
	}
};

const tailLayout = {
	wrapperCol: {
		xs: {
			span: 24,
			offset: 0
		},
		sm: {
			span: 12,
			offset: 5
		}
	}
};

const AddUpdateUserForm = ({ selected, create, update, stations, onClose }) => {
	const [form] = Form.useForm();

	let selectedStationIdInitial = stations ? stations[0]._id : '';
	if (selected) {
		const station = stations.find((r) => r._id === selected.stationId);
		selectedStationIdInitial = station ? station._id : null;
	}

	const [ selectedStation, setSelectedStation ] = useState(selectedStationIdInitial);

	const onStationChange = value => {
		setSelectedStation(value);
	};

	const onSubmit = (values) => {
		if (selected) {
			update({
				...selected,
				...values,
				stationId: selectedStation
			});
		} else {
			create({
				...values,
				stationId: selectedStation
			});
		}
		onClose();
	};

	return (
		<Form
			form={form}
			{...formItemLayout}
			initialValues={{
				email: selected ? selected.email : '',
				isAdmin: selected ? selected.isAdmin : false
			}}
			onFinish={onSubmit}
		>
			<Form.Item label="Email" name="email" rules={[{ type: 'email', message: 'Geen geldig email adres'}, { required: true, message: 'email is verplicht' } ]}>
				<Input disabled={selected} />
			</Form.Item>

			{!selected && (
				<Form.Item
					label="Password"
					name="password"
					rules={[ { required: true, message: 'password is verplicht' } ]}
				>
					<Input />
				</Form.Item>
			)}

			<Form.Item label="Station">
				<Select value={selectedStation} onChange={onStationChange} style={{ width: '100%' }}>
					{stations.map((station) => (
						<Select.Option key={`${station._id}`} value={station._id}>
							{station.name}
						</Select.Option>
					))}
				</Select>
			</Form.Item>

			<Form.Item {...tailLayout} name="isAdmin" valuePropName="checked">
				<Checkbox>Is administrator</Checkbox>
			</Form.Item>

			<Form.Item {...tailLayout}>
				<Button htmlType="submit" type="primary">
					Opslaan
				</Button>
			</Form.Item>
		</Form>
	);
};

export default AddUpdateUserForm;
