import React, { useEffect, useState, useContext } from 'react';
import AdminLayout from '../../layouts/admin';
import Loader from '../../components/loader';
import UsersTable from '../../components/admin/users/usersTable';
import AddUpdateUserForm from '../../components/admin/users/addUpdateUserForm';

import { StationContext } from '../../contexts/stationContext';
import Api from '../../api';

import { Modal, Button, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import gql from 'graphql-tag';

const listUsersQuery = gql`
	query ListUsers {
		UserMany {
			username
			isAdmin
			email
			stationId
		}
	}
`;

const createUserQuery = gql`
	mutation CreateUser($email: String!, $password: String!, $stationId: String!, $isAdmin: Boolean!) {
		UserCreateOne(email: $email, password: $password, stationId: $stationId, isAdmin: $isAdmin) {
			email
		}
	}
`;

const updateUserQuery = gql`
	mutation UpdateUser($email: String!, $stationId: String!, $isAdmin: Boolean!) {
		UserUpdateByEmail(email: $email, stationId: $stationId, isAdmin: $isAdmin) {
			email
		}
	}
`;

const removeUserQuery = gql`
	mutation RemoveUser($email: String!) {
		UserRemoveByEmail(email: $email) {
			email
		}
	}
`;

const UserPage = ({ location }) => {
	const { stations, stationsLoading, getStations } = useContext(StationContext);

	const [ showModal, setShowModal ] = useState(false);
	const [ user, setUser ] = useState(null);

	const [ users, setUsers ] = useState([]);
	const [ usersError, setUsersError ] = useState(false);
	const [ usersLoading, setUsersLoading ] = useState(true);
	
	const getUsers = async () => {
		const result = await Api.query(listUsersQuery);
		if (!result.errors) {
			setUsers(result.data.UserMany);
		} else {
			message.error('Er ging iets mis bij het ophalen van de gebruikers');
		}
		setUsersLoading(false);
	};

	useEffect(() => {
		getUsers();
		getStations();
	}, []);

	const selectUser = (userToSelect) => {
		setShowModal(userToSelect != null);
		setUser(userToSelect);
		setShowModal(userToSelect != null);
	};

	const createUser = async (userToCreate) => {
		const result = await Api.mutate(createUserQuery, userToCreate);
		if (!result.errors) {
			await getUsers();
		} else {
			message.error('Er ging iets mis bij opslaan van de gebruiker');
		}
	};

	const updateUser = async (userToUpdate) => {
		const result = await Api.mutate(updateUserQuery, userToUpdate);
		if (!result.errors) {
			await getUsers();
		} else {
			message.error('Er ging iets mis bij opslaan van de gebruiker');
		}
	};

	const removeUser = async (userToDelete) => {
    Modal.confirm({
      title: 'Weet je het zeker',
      icon: <ExclamationCircleOutlined />,
      content: `Weet je zeker dat je '${userToDelete.email}' wilt verwijderen? Dit kan niet ongedaan gemaakt worden.`,
      onOk: async () => {
				const removed = await Api.mutate(removeUserQuery, { email: userToDelete.email });
				if (removed) {
					await getUsers();
				} else {
					message.error('Er ging iets mis met het verwijderen.');
				}
      }
    });
  }

	if (usersError) {
		return <h1>Error fetching users</h1>;
	}

	return (
		<AdminLayout path={location.pathname} breadcrumbs={[{ link: '/admin/users', title: 'Gebruikers'}]}>
			<h1>
				Gebruikers
				<Button type="default" onClick={() => setShowModal(true)} disabled={user}>
					Gebruiker toevoegen
				</Button>
			</h1>

			{ ( usersLoading || stationsLoading ) ? (
				<Loader />
			) : (
				<UsersTable users={users} stations={stations} onSelect={selectUser} onRemove={removeUser} />
			)}

			{showModal && (
				<Modal
					title="Gebruiker toevoegen/wijzigen"
					visible={true}
					onCancel={() => selectUser(null)}
					footer={null}
					width={'90vw'}
				>
					<AddUpdateUserForm
						selected={user}
						stations={stations}
						create={createUser}
						update={updateUser}
						onClose={() => setShowModal(false)}
					/>
				</Modal>
			)}
		</AdminLayout>
	);
};

export default UserPage;
